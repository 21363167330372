import {BehaviorSubject, combineLatest, combineLatestWith, EMPTY as observableEmpty, forkJoin, Observable} from 'rxjs';

import {distinctUntilChanged, filter, map, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
/// <reference path="../../../utilities/Validators.ts"/>
import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PledgeAssistanceTypeType} from '../../../enums/PledgeAssistanceTypeType';
// models
// enums
import {
    Agency, AssistanceApplication, AssistanceApplicationClient,
    Client,
    Company,
    LookUp,
    Pledge,
    PledgeAssistanceType,
    PledgeStatusType,
    PledgeType,
    PledgeTypeBalance,
    Setting, SystemData,
    User, UserPledgeAccess,
} from '../../../services/api.client';
// services
import {PledgeService} from '../../../services/PledgeService';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
// utilities
import {extractEnumNames} from '../../../utilities/Util';
// modals
import {AgencyListModalComponent} from '../agency/AgencyListModalComponent';
import {CompanyListModalComponent} from '../company/CompanyListModalComponent';
// shared
import {PromptComponent} from '../../shared/dialogs/PromptComponent';
import {PledgeConfirmationComponent, PrintConfirmation} from './PledgeConfirmationComponent';
import {ClientService} from '../../../services/ClientService';
import {Validators} from '../../../utilities/Validators';
import {SystemService} from '../../../services/SystemService';
import {Masking} from '../../../utilities/Masking';
import * as moment from 'moment';
import {NotificationService} from '../../../services/NotificationService';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectClientDataCurrentClient} from '../../../store/selectors/client.selectors';
import {selectPledgeDataCurrentPledgeTypeBalance, selectPledgeDataCurrentSelectedPledge} from '../../../store/selectors/pledge.selectors';
import {
    selectSecurityStateDataCurrentUser,
    selectSecurityStateDataSecurityDataPledgeTypes, selectSecurityStateDataSystemData,
    selectSecurityStateDataSystemDataStates
} from '../../../store/selectors/security.selectors';

import {Validators as AngularValidator} from '@angular/forms';
import {IMask} from 'angular-imask';
import {AssistanceApplicationService} from '../../public/application/assistance-application.service';
import {CompanySelectedEvent} from '../../shared/events/company-selected-event';
import {Disposable} from '../../../utilities/Disposable';

@Component({
    templateUrl: './PledgeComponent.html',
    styleUrls: ['./PledgeComponent.scss'],
})

export class PledgeComponent extends Disposable implements OnInit, OnDestroy {

    constructor(private _pledgeService: PledgeService,
                private _clientService: ClientService,
                private _formBuilder: UntypedFormBuilder,
                private _route: ActivatedRoute,
                private _notificationService: NotificationService,
                public system: SystemService,
                private _router: Router,
                private _pledgeTypeService: PledgeTypeService,
                private _store: Store<fromRoot.AppState>,
                private _assistanceApplicationClient: AssistanceApplicationClient) {
        super();
        this.loggedInUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.states$ = this._store.pipe(select(selectSecurityStateDataSystemDataStates));
        this.pledgeTypes$ = this._store.pipe(select(selectSecurityStateDataSecurityDataPledgeTypes));
        this.currentClient$ = this._store.pipe(select(selectClientDataCurrentClient));
        this.currentPledge$ = this._store.pipe(select(selectPledgeDataCurrentSelectedPledge));
        this.systemData$ = this._store.pipe(select(selectSecurityStateDataSystemData));
        this.currentPledgeTypeBalance$ = this._store.pipe(select(selectPledgeDataCurrentPledgeTypeBalance));
    }

    assistanceTypeErrorMessage: any;
    pledgeStatuses = extractEnumNames(PledgeStatusType);
    pledgeStatusTypes = PledgeStatusType;
    pledgeAssistances = PledgeAssistanceType;
    originalAmount: any = 0;

    currencyMaskOptions = Masking.currencyMask;
    currencyMask = Masking.currencyMask;
    zipCodeMask = Masking.zipMask;

    assistanceTypes = extractEnumNames(PledgeAssistanceTypeType);
    states: LookUp[];
    states$: Observable<LookUp[]>;

    @ViewChild('confirmPrompt')
    confirmPrompt: PledgeConfirmationComponent;

    @ViewChild('deletePrompt')
    deletePrompt: PromptComponent;
    excludedPledgeStatuses: any[];

    isExpedite: boolean;

    pledgeTypes: PledgeType[];
    pledgeTypes$: Observable<PledgeType[]> = null;
    filterPledgeTypes$: Observable<PledgeType[]>;
    filterPledgeTypes: PledgeType[];

    // subscriptions
    currentClient: Client;
    currentClient$: Observable<Client>;

    currentPledge: Pledge;
    currentPledge$: Observable<Pledge>;

    loggedInUser: User;
    loggedInUser$: Observable<User>;

    assistanceApplication: AssistanceApplication;
    userPledgeAccess: UserPledgeAccess;

    form: UntypedFormGroup;

    public missingClientIncome = false;
    public loading = new BehaviorSubject(true);

    get clientId(): FormControl {
        return this.form.get('clientId') as FormControl;
    }

    get pledgeTypeId(): FormControl {
        return this.form.get('pledgeTypeId') as FormControl;
    }

    get agencyId(): FormControl {
        return this.form.get('agencyId') as FormControl;
    }

    get agencyName(): FormControl {
        return this.form.get('agencyName') as FormControl;
    }

    get companyId(): FormControl {
        return this.form.get('companyId') as FormControl;
    }

    get companyName(): FormControl {
        return this.form.get('companyName') as FormControl;
    }

    get clientCompanyAccountNumber(): FormControl {
        return this.form.get('clientCompanyAccountNumber') as FormControl;
    }

    get pledgedAmount(): FormControl {
        return this.form.get('pledgedAmount') as FormControl;
    }

    get pledgedDate(): FormControl {
        return this.form.get('pledgedDate') as FormControl;
    }

    get enteredDate(): FormControl {
        return this.form.get('enteredDate') as FormControl;
    }

    get modifiedDate(): FormControl {
        return this.form.get('modifiedDate') as FormControl;
    }

    get paidDate(): FormControl {
        return this.form.get('paidDate') as FormControl;
    }

    get status(): FormControl {
        return this.form.get('status') as FormControl;
    }

    get isCrisisPledge(): FormControl {
        return this.form.get('isCrisisPledge') as FormControl;
    }

    get leverageAmount(): FormControl {
        return this.form.get('leverageAmount') as FormControl;
    }

    get assistanceType(): FormControl {
        return this.form.get('assistanceType') as FormControl;
    }

    get onetimeCompanyRecipient(): FormControl {
        return this.form.get('onetimeCompanyRecipient') as FormControl;
    }

    get onetimeCompanyAddress1(): FormControl {
        return this.form.get('onetimeCompanyAddress1') as FormControl;
    }

    get onetimeCompanyAddress2(): FormControl {
        return this.form.get('onetimeCompanyAddress2') as FormControl;
    }

    get onetimeCompanyCity(): FormControl {
        return this.form.get('onetimeCompanyCity') as FormControl;
    }

    get onetimeCompanyState(): FormControl {
        return this.form.get('onetimeCompanyState') as FormControl;
    }

    get onetimeCompanyZip(): FormControl {
        return this.form.get('onetimeCompanyZip') as FormControl;
    }

    get checkMemo(): FormControl {
        return this.form.get('checkMemo') as FormControl;
    }

    get agencyEfnCaseId(): FormControl {
        return this.form.get('agencyEfnCaseId') as FormControl;
    }

    pledgeType: PledgeType;

// pledge type property {
    get ledgerNumber(): FormControl {
        return this.form.get('ledgerNumber') as FormControl;
    }

    get usesOneTimeCompany(): FormControl {
        return this.form.get('usesOneTimeCompany') as FormControl;
    }

    // -------- agency modal ------------
    selectedAgencyName = 'Choose an Agency';

    @ViewChild(AgencyListModalComponent)
    agencyListModal: AgencyListModalComponent;
    @Output('onShowAgencyModal') _showAgencies = new EventEmitter();
    private agencyList: AgencyListModalComponent;

    accountNumberErrorMessage = '';

    amountPledgedErrorMessage = '';

    // -------- company modal ------------
    selectedCompanyName = 'Choose a Company';
    selectedCompany: Company;

    @ViewChild(CompanyListModalComponent)
    companyListModal: CompanyListModalComponent;

    // -----------------------------------
    is211 = new FormControl(false);

    setting: Setting;
    systemData$: Observable<SystemData>;

    yearlyMaxValidate: boolean;

    currentPledgeTypeBalance: PledgeTypeBalance;
    currentPledgeTypeBalance$: Observable<PledgeTypeBalance>;

    submitDisabled: boolean;
    isDirty: boolean;

    submit(): Observable<Pledge[]> {
        const pledge = this.form.value;
        if (!this.form.valid) {
            return observableEmpty;
        }
        if (pledge.id) {
            const result = Object.assign({}, this.currentPledge, pledge);
            return this._pledgeService.updatePledge(result)
                .pipe(
                    mergeMap((next) =>
                        this._pledgeService.getCurrenClientPledgeList(next.clientId)
                            .pipe(
                                tap(() => this._router.navigate(['/app/client', next.clientId, 'assistance']))
                            )
                    )
                );
        } else {
            pledge.clientId = this.currentClient.id;
            const addPledge = Pledge.fromJS({
                clientId: pledge?.clientId || null,
                pledgeTypeId: pledge?.pledgeTypeId || null,
                agencyId: pledge?.agencyId || null,
                companyId: pledge?.companyId || null,
                agencyName: pledge?.agencyName || null,
                companyName: pledge?.companyName || null,
                pledgedAmount: pledge?.pledgedAmount,
                status: pledge?.status || PledgeStatusType.Confirmed,
                clientCompanyAccountNumber: pledge?.clientCompanyAccountNumber || null,
                clientMissingSsn: pledge?.clientMissingSsn || null,
                clientFirstName: pledge?.clientFirstName || null,
                clientLastName: pledge?.clientLastName || null,
                clientLast4Ssn: pledge?.clientLast4Ssn || null,
                paidDate: pledge?.paidDate || null,
                pledgedDate: pledge?.pledgedDate,
                federalPovertyLevelDisplay: pledge?.federalPovertyLevelDisplay || null,
                isCrisisPledge: pledge?.isCrisisPledge || false,
                assistanceType: pledge?.assistanceType || null,
                leverageAmount: pledge?.leverageAmount || null,
                checkMemo: pledge?.checkMemo || null,
                onetimeCompanyRecipient: pledge?.onetimeCompanyRecipient || null,
                onetimeCompanyAddress1: pledge?.onetimeCompanyAddress1 || null,
                onetimeCompanyAddress2: pledge?.onetimeCompanyAddress2 || null,
                onetimeCompanyCity: pledge?.onetimeCompanyCity || null,
                onetimeCompanyState: pledge?.onetimeCompanyState || null,
                onetimeCompanyZip: pledge?.onetimeCompanyZip || null,
                agencyEfnCaseId: pledge?.agencyEfnCaseId || null,
                uwdaApprovedById: pledge?.uwdaApprovedById || null,
                uwdaApprovedByName: pledge?.uwdaApprovedByName || null,
                clientUnionInfo: pledge?.clientUnionInfo || null
            });
            return this._pledgeService.addPledge(addPledge)
                .pipe(
                    mergeMap((result) =>
                        this._pledgeService.getCurrenClientPledgeList(result.clientId)
                            .pipe(
                                tap(() => this._router.navigate(['/app/client', result.clientId, 'assistance']))
                            )
                    )
                );
        }
    }

    showDeletePrompt(): boolean {
        this.deletePrompt.open().subscribe(okClicked => {
            if (okClicked) {
                this.disabledForm(this._pledgeService.deletePledge(this.currentPledge.id)).pipe(
                    mergeMap((result) => this._pledgeService.getCurrenClientPledgeList(result.clientId)))
                    .subscribe(() => {
                        this._router.navigate(['/app/client', this.currentClient.id, 'assistance']).then();
                    });
            } else {
                return false;
            }
        });

        return false;
    }


    isEdit(): string {
        return this.currentPledge && this.currentPledge.id;
    }

    // On Init
    ngOnInit(): void {
        this.loggedInUser$.subscribe(user => this.loggedInUser = user);
        this.states$.subscribe(states => this.states = states);
        this.pledgeTypes$.subscribe(pledgeTypes => this.pledgeTypes = pledgeTypes);
        this.currentClient$.subscribe(currentClient => {
            this.currentClient = currentClient;
        });
        this.currentPledge$.subscribe(currentPledge => this.currentPledge = currentPledge);
        this.systemData$.subscribe(data => this.setting = data.setting);
        this.currentPledgeTypeBalance$.subscribe(currentPledgeTypeBalance => this.currentPledgeTypeBalance = currentPledgeTypeBalance);
        this.filterPledgeTypes$ = this.pledgeTypes$
            .pipe(
                // we should never show keeping current and EFS pledge types here
                map((pledges) => pledges.filter((p) => !p.isKeepingCurrent && !p.isEfs)));
        this.filterPledgeTypes$.subscribe(filterPledgeTypes => this.filterPledgeTypes = filterPledgeTypes);
        this.initForm();

        this._route.data.pipe(
            map((data) => data.pledge),
        )
            .subscribe((pledge: Pledge) => {
                this.onAgencySelected(this.loggedInUser.agency);
                if (pledge && pledge.id) { // edit
                    // is it One-time payee pledge
                    if (pledge.companyId == null && pledge.onetimeCompanyRecipient) {
                        this.usesOneTimeCompany.setValue(true);
                    }

                    this.setEditPermissions(pledge.id);

                    this._assistanceApplicationClient.getByPledgeId(pledge?.id)
                        .subscribe((assistanceApplication) => {
                            this.assistanceApplication = assistanceApplication;
                        });
                } else { // add
                    if (this.system.isViewer) {
                        this.form.disable();
                    } else if (this.system.isSystemAdmin || this.system.isRegionAdmin) {
                        this.status.enable();
                    } else {
                        this.status.disable();
                    }
                    if (this.system.isSystemAdmin || this.system.isFundAdmin || this.system.isRegionAdmin) {
                        this.agencyName.enable({onlySelf: true});
                    } else {
                        this.agencyName.disable({onlySelf: true});
                    }
                    const obj = this._router.parseUrl(this._router.url).queryParams;
                    Object.keys(obj).forEach(key => {
                        if (obj[key] === 'null') {
                            obj[key] = null;
                        }
                    });
                    this.form.patchValue(obj);
                    this.selectedCompanyName = this.companyName.value;
                    this.loading.next(false);
                }

            }, _ => {
                this._notificationService.showError('Error loading pledge.');
                this.loading.next(false);
            });
        this.currentPledge$.pipe(
            filter(pledge => !!(pledge && pledge.id)))
            .subscribe(pledge => {
                const fields = Object.assign({}, pledge, {});
                if (fields.pledgedDate) {
                    (fields as any).pledgedDate = moment(fields.pledgedDate);
                    (fields as any).pledgedDate.toString = () => (fields as any).pledgedDate.format('L');
                }

                if (fields.enteredDate) {
                    (fields as any).enteredDate = moment(fields.enteredDate);
                    (fields as any).enteredDate.toString = () => (fields as any).enteredDate.format('MM/DD/YYYY HH:mm a');
                }

                if (fields.modifiedDate) {
                    (fields as any).modifiedDate = moment(fields.modifiedDate);
                    (fields as any).modifiedDate.toString = () => (fields as any).modifiedDate.format('L');
                }

                if (fields.paidDate) {
                    (fields as any).paidDate = moment(fields.paidDate);
                    (fields as any).paidDate.toString = () => (fields as any).paidDate.format('L');
                }

                this.status.setValue(pledge.status + '', {emitEvent: true, emitViewToModelChange: true});
                this.pledgeTypeId.setValue(pledge.pledgeTypeId);
                this.pledgeType = this.getPledgeTypeById(pledge.pledgeTypeId);
                this.originalAmount = pledge.pledgedAmount;

                if (fields.company) {
                    const companyEvent = {company: fields.company, payee: null} as CompanySelectedEvent;
                    this.onCompanySelected(companyEvent);
                }
                if (fields.agency) {
                    this.onAgencySelected(fields.agency);
                }
                this.form.patchValue(fields);

                this.form.markAsPristine();

                this._clientService.getClientById(pledge.clientId);
            });

        this.currentClient$.pipe(
            filter(currentClient => !!currentClient))
            .subscribe((currentClient) => {
                this.clientId.setValue(currentClient.id);
                this.currentClient = currentClient;
                this.form.markAsPristine();
            });

        this.checkClientIncomeOnUhonsFundSelect();
    }

    private checkClientIncomeOnUhonsFundSelect(): void {
        this.pledgeTypeId.valueChanges.pipe(takeUntil(this.$destroyed), filter(pledgeTypeId => !!pledgeTypeId)).subscribe((pledgeTypeId) => {
            const pledgeType = this.getPledgeTypeById(pledgeTypeId);
            this.missingClientIncome = pledgeType?.isUhons && (this.currentClient?.individualMonthlyIncome === null || this.currentClient?.individualMonthlyIncome === undefined);
        });
    }

    showAgencyModal(): boolean {// show Agency Modal
        this.agencyListModal.open();
        return false;
    }

    onAgencySelected(selectedAgency: Agency): void {// on Agency Selected
        if (selectedAgency) {
            selectedAgency.agencyName ? this.selectedAgencyName = selectedAgency.agencyName : this.selectedAgencyName = '';
            this.agencyId.setValue(selectedAgency.id,
                {onlySelf: false, emitModelToViewChange: true, emitViewToModelChange: true});
            this.agencyName.setValue(selectedAgency.agencyName, {onlySelf: false});
            this.agencyId.markAsDirty();
        }
    }

    setEditPermissions(pledgeId: string): void {
        this._pledgeService.canCurrentUserManagePledge(pledgeId).subscribe(userPledgeAccess => {
                this.userPledgeAccess = userPledgeAccess;
                userPledgeAccess.canEditPledge ? this.form.enable() : this.form.disable();
                userPledgeAccess.canChangeStatus ? this.status.enable() : this.status.disable();
                userPledgeAccess.canChangeAgency ? this.agencyName.enable() : this.agencyName.disable();
                this.loading.next(false);
            }, error => {
                this._notificationService.showError('There was an error checking the user\'s access to this Pledge.');
                this.loading.next(false);
            }
        );
    }

    // -----------------------------------

    reprintConfirmation(): void {
        this.showConfirmPrompt();
    }

    prompt(isManual) { // is Expedite == manual check
        this.savePledge(isManual);
    }

    savePledge(isManual = false): void {
        this.form.markAllAsTouched();
        this.isExpedite = isManual;


        if (this.is211) {
            this.leverageAmount.addValidators(AngularValidator.required);
            this.assistanceType.addValidators(AngularValidator.required);

        }

        if (!this.form.valid) {
            return;
        }


        if (!this.isEdit()) {
            if (this.system.canCreatePledge()) {
                this.showConfirmPrompt();
            }
        } else if (this.userPledgeAccess.canEditPledge) {
            this.showConfirmPrompt();
        }
    }

    validateAccountNumber(): boolean {
        this.accountNumberErrorMessage = '';
        const company = this.selectedCompany;
        if (!company || !company.isAccountValidationEnforced) {
            return true;
        }
        const accountNumber = this.clientCompanyAccountNumber.value + '';
        let regex = '';
        if (company.isAccountValidAlphabetical) {
            regex += 'a-zA-Z';
        }
        if (company.isAccountValidNumerical) {
            regex += '0-9';
        }
        if (company.isAccountValidSpecialChars) {
            regex += '\-\(\)';
        }
        const min = company.accountValidationMinLen || 0;
        const max = company.accountValidationMaxLen || 100;
        if (!(new RegExp(`^[${regex}]{${min},${max}}$`)).test(accountNumber)) {
            if ((company.accountValidationMaxLen !== null &&
                    company.accountValidationMinLen !== null &&
                    company.accountValidationMaxLen === company.accountValidationMinLen) &&
                accountNumber.length !== company.accountValidationMinLen) {
                this.accountNumberErrorMessage = `Account number must be ${company.accountValidationMinLen} characters. ` +
                    company.accountValidationErrorMsg;
                return false;
            }
            if (company.accountValidationMinLen && accountNumber.length < company.accountValidationMinLen) {
                this.accountNumberErrorMessage = `Account number must be at least ${company.accountValidationMinLen} characters. ` +
                    company.accountValidationErrorMsg;
                return false;
            }
            if (company.accountValidationMaxLen && accountNumber.length > company.accountValidationMaxLen) {
                this.accountNumberErrorMessage = `Account number cannot be greater than ${company.accountValidationMaxLen} characters. ` +
                    company.accountValidationErrorMsg;
                return false;
            }
            let allowedCharacters = '';
            if (company.isAccountValidAlphabetical && company.isAccountValidNumerical) {
                allowedCharacters = 'alphanumeric';
            } else if (company.isAccountValidAlphabetical) {
                allowedCharacters = 'alphabetic';
            } else if (company.isAccountValidNumerical) {
                allowedCharacters = 'numerical';
            }
            this.accountNumberErrorMessage = `Account number may only contain ${allowedCharacters} characters. ` +
                company.accountValidationErrorMsg;
            return false;
        }
        return true;
    }

    validateAmountPledged(): boolean {
        if (!this.pledgeTypeId.value || !this.currentPledgeTypeBalance || !this.pledgeType) {
            return true;
        }
        const amount = this.pledgedAmount.value;
        const pledgeTypeBalance = this.currentPledgeTypeBalance.balance;

        if (this.yearlyMaxValidate === false) {
            return false;
        } else if (amount < this.pledgeType.minSinglePledgeAmt) {
            const minDeficit = this.pledgeType.minSinglePledgeAmt - amount;
            this.amountPledgedErrorMessage = 'This is less than the selected Fund Type\'s minimum pledge by $' + minDeficit.toFixed(2) + '.';
            return false;
        } else if (amount > this.originalAmount) {
            const difference = this.originalAmount > 0 ? amount - this.originalAmount : amount;

            const deficit = this.originalAmount > 0 ? amount - (this.originalAmount + pledgeTypeBalance) : amount - pledgeTypeBalance;

            if (difference > pledgeTypeBalance) {
                this.amountPledgedErrorMessage = 'This exceeds the selected Fund Type\'s current balance by $' + deficit.toFixed(2) + '.';
                return false;
            } else if (this.pledgedAmount.value > this.pledgeType.crisisPledgeThreshold) {
                if (this.isCrisisPledge.value) {
                    this.amountPledgedErrorMessage = '';
                    return true;
                } else {
                    this.amountPledgedErrorMessage = 'You are in crisis pledge range. Please check the crisis checkbox to proceed';
                    return false;
                }
            } else {
                this.amountPledgedErrorMessage = '';
                return true;
            }
        } else {
            this.amountPledgedErrorMessage = '';
            return true;
        }
    }

    checkClientsYearlyMaxPledge(clientId: string, pledgeTypeId: string): Observable<PledgeTypeBalance> {
        return this._pledgeService.checkMaxYearlyPledgeBalance(clientId, pledgeTypeId);
    }

    showCompanyModal(): boolean {// show Company Modal
        if (!this.pledgeType) {
            return;
        }
        const selectedRegion = this.pledgeType ? this.pledgeType.regionValue : null;
        this.companyListModal.openModal(selectedRegion);
        return false;
    }

    onCompanySelected(selectedCompany: CompanySelectedEvent): void {// on Company Selected
        if (selectedCompany?.company) {
            this.selectedCompanyName = selectedCompany.company.name;
            this.selectedCompany = selectedCompany.company;
            this.companyId.setValue(selectedCompany.company.id, {
                onlySelf: false,
                emitModelToViewChange: true,
                emitViewToModelChange: true
            });
            this.companyName.setValue(selectedCompany.company.name, {onlySelf: false});
            this.companyId.markAsDirty();
            this.resetOneTimeCompany();
        } else if (!this.selectedCompany || selectedCompany?.noCompany) {
            this.selectedCompany = null;
            // this.usesOneTimeCompany = false;
            this.companyId.setValue(null, {onlySelf: false, emitModelToViewChange: true, emitViewToModelChange: true});
            this.companyName.setValue(null, {
                onlySelf: false,
                emitModelToViewChange: true,
                emitViewToModelChange: true
            });

        }
    }

    resetOneTimeCompany(): void {
        this.usesOneTimeCompany.setValue(false);
        this.onetimeCompanyRecipient.setValue(null);
        this.onetimeCompanyAddress1.setValue(null);
        this.onetimeCompanyAddress2.setValue(null);
        this.onetimeCompanyCity.setValue(null);
        this.onetimeCompanyState.setValue(null);
        this.onetimeCompanyZip.setValue(null);
    }

    // init Form
    initForm(): void {
        this.form = this._formBuilder.group({
            usesOneTimeCompany: new FormControl(false),
            id: new FormControl(null),
            is211: new FormControl(false),
            clientId: new FormControl(null, Validators.required),
            pledgeTypeId: new FormControl(null, Validators.required),
            agencyId: new FormControl(null, Validators.required),
            agencyName: new FormControl({value: null, disabled: true}, Validators.required),
            companyId: new FormControl(null, AngularValidator.required),
            companyName: new FormControl(null, AngularValidator.required),
            clientCompanyAccountNumber: new FormControl(null, AngularValidator.required),
            pledgedAmount: new FormControl<number | string>(0, Validators.required),
            pledgedDate: new FormControl(null, Validators.compose([
                Validators.required, Validators.isDateValid,
                Validators.dateRange(moment().subtract(this.setting.daysBackToPledge, 'days'), moment())])),
            enteredDate: new FormControl(null),
            modifiedDate: new FormControl(null),
            paidDate: new FormControl(null, Validators.isDateValid),
            isCrisisPledge: new FormControl({value: null, disabled: true}),
            status: new FormControl(PledgeStatusType.Confirmed),
            leverageAmount: new FormControl<number | null>(0, Validators.requiredWhen('is211', true, 0)),
            assistanceType: new FormControl(null, Validators.requiredWhen('is211', true)),
            onetimeCompanyRecipient: new FormControl(null),
            onetimeCompanyAddress1: new FormControl(null),
            onetimeCompanyAddress2: new FormControl(null),
            onetimeCompanyCity: new FormControl(null),
            onetimeCompanyState: new FormControl(null),
            onetimeCompanyZip: new FormControl<string | null>(null),
            checkMemo: new FormControl(null),
            agencyEfnCaseId: new FormControl('')
        });
        this.form.updateValueAndValidity();

        // reset company Id/Name if one of the OneTimePay fields has value
        combineLatest([
            this.onetimeCompanyRecipient.valueChanges
            , this.onetimeCompanyAddress1.valueChanges
            , this.onetimeCompanyAddress2.valueChanges
            , this.onetimeCompanyCity.valueChanges
            , this.onetimeCompanyState.valueChanges
            , this.onetimeCompanyZip.valueChanges])
            .subscribe(([
                            onetimeCompanyRecipient,
                            onetimeCompanyAddress1,
                            onetimeCompanyAddress2,
                            onetimeCompanyCity,
                            onetimeCompanyState,
                            onetimeCompanyZip]) => {
                if (
                    onetimeCompanyRecipient ||
                    onetimeCompanyAddress1 ||
                    onetimeCompanyAddress2 ||
                    onetimeCompanyCity ||
                    onetimeCompanyState ||
                    onetimeCompanyZip) {
                    this.companyId.setValue(null);
                    this.companyName.setValue(null);
                }
            });

        // this handles ensuring usesOneTimeCompany is updated for the requiredWhen validation as soon as the value changes distinctly
        this.usesOneTimeCompany
            .valueChanges.pipe(
            distinctUntilChanged())
            .subscribe(_ => {
                this.companyId.clearValidators();
                this.companyName.clearValidators();

                this.companyId.setErrors(null);
                this.companyName.setErrors(null);
                this.clientCompanyAccountNumber.setErrors(null);
                this.onetimeCompanyRecipient.setErrors(null);
                this.onetimeCompanyAddress1.setErrors(null);
                this.onetimeCompanyCity.setErrors(null);
                this.onetimeCompanyState.setErrors(null);
                this.onetimeCompanyZip.setErrors(null);

                this.clientCompanyAccountNumber.clearValidators();
                this.onetimeCompanyRecipient.clearValidators();
                this.onetimeCompanyAddress1.clearValidators();
                this.onetimeCompanyCity.clearValidators();
                this.onetimeCompanyState.clearValidators();
                this.onetimeCompanyZip.clearValidators();

                if (this.usesOneTimeCompany.value) {
                    this.onetimeCompanyRecipient.addValidators(AngularValidator.required);
                    this.onetimeCompanyAddress1.addValidators(AngularValidator.required);
                    this.onetimeCompanyCity.addValidators(AngularValidator.required);
                    this.onetimeCompanyState.addValidators(AngularValidator.required);
                    this.onetimeCompanyZip.addValidators(AngularValidator.required);
                } else {
                    this.companyId.addValidators(AngularValidator.required);
                    this.companyName.addValidators(AngularValidator.required);
                    this.clientCompanyAccountNumber.addValidators(AngularValidator.required);
                }
                this.form.updateValueAndValidity();

            });

        this.is211.valueChanges.pipe(distinctUntilChanged()).subscribe(_ => {
            this.leverageAmount.clearValidators();
            this.assistanceType.clearValidators();

            if (this.is211.value) {
                this.leverageAmount.addValidators(AngularValidator.required);
                this.assistanceType.addValidators(AngularValidator.required);
            }
            this.form.updateValueAndValidity();
        });

        // this handles ensuring assistanceType is updated for the requiredWhen validation as soon as the value changes distinctly
        this.assistanceType
            .valueChanges.pipe(
            distinctUntilChanged())
            .subscribe(_ => {
                if (this.assistanceType.value === 'null') {
                    this.assistanceType.setValue(null);
                    this.assistanceTypeErrorMessage = 'Assistance Type must be selected for the ' + this.pledgeType.name;
                } else {
                    this.assistanceTypeErrorMessage = '';
                }
                this.form.updateValueAndValidity();
            });

        // //this handles populating the usesOneTimeCompany on edit
        this.companyId
            .valueChanges.pipe(
            take(1))
            .subscribe(id => {
                if (id) {
                    this.usesOneTimeCompany.setValue(false);
                }
            });


        this.pledgeTypeId
            .valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(id => {
                this.pledgeType = this.getPledgeTypeById(id);
                if (this.pledgeType && this.pledgeType.isKeepingCurrent) {
                    this.excludedPledgeStatuses = [];
                } else {
                    // only keeping current can have 'new' status type
                    this.excludedPledgeStatuses = [PledgeStatusType.New];
                }
                if (this.pledgeType) {
                    this.is211?.setValue(this.pledgeType.isUwda);
                    this.leverageAmount.updateValueAndValidity();
                    this.assistanceType.updateValueAndValidity();
                }
            });
        this.pledgeTypeId.updateValueAndValidity({onlySelf: true});

        combineLatest([
            this.agencyId.valueChanges.pipe(filter(id => !!id), distinctUntilChanged(),),
            this.pledgeTypeId.valueChanges.pipe(filter(id => !!id), distinctUntilChanged(),)
        ])
            .subscribe(([agencyId, pledgeTypeId]) => {
                this.fetchFundBalance(agencyId, pledgeTypeId);
            });

        combineLatest([
            this.pledgeTypeId.valueChanges,
            this.isCrisisPledge.valueChanges
        ]).subscribe(() => this.validateAmountPledged());

        // let amount = this.pledgedAmount.value;

        combineLatest([
            this.pledgeTypeId.valueChanges.exists().pipe(distinctUntilChanged()),
            this.pledgedAmount.valueChanges.pipe(distinctUntilChanged()),
            this.pledgeTypes$.exists(),
            this.currentClient$.exists()
        ]).pipe(
            tap(() => this.yearlyMaxValidate = false),
            map(([pledgeTypeId]) => this.getPledgeTypeById(pledgeTypeId)),
            filter((pt) => !!pt),
            mergeMap((pledgeType) => {
                return this.checkClientsYearlyMaxPledge(this.clientId.value, pledgeType.id)
                    .pipe(map((checkMax) => [pledgeType, checkMax.yearlyPledgeTotal]));
            }),)
            .subscribe(([pledgeType, yearlyPledgeBalance]: [PledgeType, number]) => {
                let remainingYearly = pledgeType.maxYearlyPledgeAmt - yearlyPledgeBalance;
                remainingYearly = Number(remainingYearly.toFixed(2));
                let delta = this.originalAmount > 0
                    ? Number(this.pledgedAmount.value || 0) - this.originalAmount
                    : Number(this.pledgedAmount.value || 0);
                delta = Number(delta.toFixed(2));
                if (delta > remainingYearly) {
                    const difference = delta - remainingYearly;
                    this.yearlyMaxValidate = false;
                    this.amountPledgedErrorMessage = 'This exceeds the ' + this.pledgeType.name + '\'s yearly maximum amount by $' +
                        difference.toFixed(
                            2) + '.';
                } else {
                    this.yearlyMaxValidate = true;
                    this.amountPledgedErrorMessage = '';
                    if (this.pledgedAmount.value > pledgeType.crisisPledgeThreshold) {
                        this.isCrisisPledge.setValidators(Validators.required);
                        this.isCrisisPledge.enable();
                    } else {
                        this.isCrisisPledge.clearValidators();
                        this.isCrisisPledge.disable();
                    }
                    this.validateAmountPledged();
                }
            });
    }

    showConfirmPrompt(): void {
        this.confirmPrompt.isReprint = !this.form.dirty && this.currentPledge && !!this.currentPledge.id;
        this.confirmPrompt
            .open()
            .subscribe((action: PrintConfirmation) => {
                switch (action) {
                    case PrintConfirmation.Cancel:
                    case PrintConfirmation.Print:
                        this.confirmPrompt.close();
                        break;
                    case PrintConfirmation.PrintAndConfirm:
                    case PrintConfirmation.Confirm:
                        this.confirmPrompt.disableButtons = true;
                        this.submit()
                            .subscribe({
                                next: () => {
                                },
                                error: () => {
                                    this.confirmPrompt.disableButtons = false;
                                    this._notificationService.showError('Error updating pledge.');
                                },
                                complete: () => this.confirmPrompt.disableButtons = false
                            });
                        break;
                    default:
                }
            });

    }

    // set client name
    getClientName(): string {
        if (this.currentClient) {
            return this.currentClient.firstName + ' ' + this.currentClient.lastName;
        } else if (this.currentPledge) {
            return this.currentPledge.clientFirstName + ' ' + this.currentPledge.clientLastName;
        }
        return '';
    }

    fetchFundBalance(agencyId: string, pledgeTypeId: string): void {
        this._pledgeService.getPledgeTypeBalance(agencyId, pledgeTypeId);
    }

    getPledgeTypeById(pledgeTypeId: string): PledgeType {
        return pledgeTypeId && this.pledgeTypes.find(pledge => pledge.id === pledgeTypeId);
    }

    hasSelectedPledgeType(): PledgeType {
        return this.pledgeType;
    }

    isValid(): boolean {
        if ((this.form.valid && !this.form.errors) &&
            this.validateAccountNumber() &&
            this.validateAmountPledged() &&
            this.validateAssistanceType()) {
            return true;
        } else {
            this.form.markAsTouched();
            return false;
        }
    }

    validateAssistanceType(): boolean {
        if (!this.is211) {
            return true; // if it's not 211 DA (UWDA) we don't care about Assistance Type
        } else {
            return !!this.assistanceType;
        }
    }

    canBeDeleted(): boolean { // or edited
        if (!this.currentPledge) {
            return false;
        }
        return this.currentPledge.id && (this.currentPledge.status === PledgeStatusType.Confirmed) ||
            (this.currentPledge.status === PledgeStatusType.New) ||
            (this.system.isFundAdmin &&
                this.currentPledge.status !== PledgeStatusType.Paid
                && this.currentPledge.status !== PledgeStatusType.Deleted
                && !this.currentPledge.pledgeType.isEfs) ||
            this.system.isSystemAdmin;
    }

    dateChanged(): void {
        this.pledgedDate.markAsDirty();
    }

    paidDateChanged(): void {
        this.paidDate.markAsDirty();
    }

    disabledForm<T>(request: Observable<T>): Observable<T> {
        this.submitDisabled = true;
        request.subscribe({
            next: () => {

            }, error: () => {
                this.submitDisabled = false;
            }
        });
        return request;
    }

    goToAssistanceApplication(): void {
        this._router.navigate([`/app/client/${this.clientId?.value}/assistance-applications/${this.assistanceApplication.id}`]);
    }

    ngOnDestroy(): void {
        this._pledgeService.setCurrentSelectedPledgeAction(null);
        this._pledgeTypeService.setPledgeTypesAction(null);
        this._pledgeTypeService.setAllPledgeTypesAction(null);
    }

    protected readonly PledgeStatusType = PledgeStatusType;
}
